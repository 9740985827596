import { reactive } from 'vue';

const store = reactive({
  token: '',
  showroom: null,
  userType: '',
  setToken(token: string) {
    this.token = token ?? '';
    localStorage.setItem('Token', this.token);
  },
  setUserType(userType: string) {
    this.userType = userType ?? null;
    localStorage.setItem('UserType', this.userType);
  },
  setShowroom(showroom: any) {
    this.showroom = showroom ?? null;
    sessionStorage.setItem('Showroom', JSON.stringify(this.showroom));
  },
});

export default store;

(function () {
  const token = localStorage.getItem('Token');
  if (token != null) {
    store.token = token;
  }
  const userType = localStorage.getItem('UserType');
  if (userType != null) {
    store.userType = userType;
  }
  const showroomCache = sessionStorage.getItem('Showroom');
  if (!showroomCache) return;
  store.showroom = JSON.parse(showroomCache);
}());
