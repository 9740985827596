import { createApp } from 'vue';
// eslint-disable-next-line
import ElementPlus from 'element-plus';
// eslint-disable-next-line
import 'element-plus/dist/index.css';
import App from './App.vue';
import router from './router';

const app = createApp(App);
app.use(router).use(ElementPlus).mount('#app');
